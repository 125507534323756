import _isobject from "isobject";
import _hasValues from "has-values";
import _getValue from "get-value";
var exports = {};
var isObject = _isobject;
var hasValues = _hasValues;
var get = _getValue;

exports = function (val, prop) {
  return hasValues(isObject(val) && prop ? get(val, prop) : val);
};

export default exports;